import React, { useState } from "react";
import { Form } from "antd";
import Card from "components/common/card";
import InputStyled from "components/common/inputStyled";
import ButtonStyled from "components/common/buttonStyled";

const FormItem = Form.Item;

const Security = () => {
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const handleSubmit = () => {};

  return (
    <Card title="Manage your Password">
      <div className="view">
        <Form
          name="resetPassword"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <FormItem
            label="Old Password"
            name="old_password"
            rules={[{ message: "Please input your password", required: true }]}
          >
            <InputStyled.Password placeholder="Password" />
          </FormItem>

          <FormItem
            label="New Password"
            name="new_password"
            rules={[{ message: "Please input your password", required: true }]}
          >
            <InputStyled.Password placeholder="Password" />
          </FormItem>

          <FormItem
            label="Confirm New Password"
            name="re_password"
            rules={[{ message: "Please input your password", required: true }]}
          >
            <InputStyled.Password placeholder="Password" />
          </FormItem>

          <ButtonStyled
            className="btn-save"
            htmlType="submit"
            type="primary"
            size="large"
            full
            loading={loading}
          >
            Save Changes
          </ButtonStyled>
        </Form>
      </div>
    </Card>
  );
};

export default Security;
