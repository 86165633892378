import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Form, Modal, notification, Row, Select } from "antd";
import Heading from "components/common/heading";
import InputStyled from "components/common/inputStyled";
import CustomSelect from "components/common/customSelect";
import ButtonStyled from "components/common/buttonStyled";
import CustomSelectDropdown from "components/common/customSelectDropdown";
import { ReactComponent as UserIcon } from "assets/svgs/user.svg";
import { ReactComponent as SelectDropdownIcon } from "assets/svgs/select-dropdown.svg";
import styled from "styled-components";
import { IUserSignUpProps } from "api/auth";
import api from "api";

const FormItem = Form.Item;
const Option = Select.Option;

const CreateAccount = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState([]);

  const [form] = Form.useForm();

  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setError([]);

      const values = await form.validateFields();

      const payload = { ...values, role: "user" } as IUserSignUpProps;

      const response = await api.auth.createAccount(payload);

      setLoading(false);

      if (response.status === 201) {
        notification.success({
          message: "Your account has been created successfully.",
        });

        navigate("/verify-account");
      }
    } catch (error: any) {
      console.log(error);
      setLoading(false);
      setError(error);
    }
  };

  return (
    <Section>
      <AccountWrapper>
        <Heading className="title">Create Account</Heading>

        <Form
          name="createAccount"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Row gutter={24}>
            <Col sm={24} md={12}>
              <FormItem
                label="First Name"
                name="first_name"
                rules={[
                  {
                    message: "Please input your first name",
                    required: true,
                  },
                ]}
              >
                <InputStyled placeholder="First Name" />
              </FormItem>
            </Col>

            <Col sm={24} md={12}>
              <FormItem
                label="Last Name"
                name="last_name"
                rules={[
                  {
                    message: "Please input your last name",
                    required: true,
                  },
                ]}
              >
                <InputStyled placeholder="Last Name" />
              </FormItem>
            </Col>
          </Row>

          <FormItem
            label="Customer Type"
            name="user_type"
            rules={[
              {
                message: "Please select customer type",
                required: true,
              },
            ]}
          >
            <CustomSelect
              prefixIcon={<UserIcon />}
              suffixIcon={<SelectDropdownIcon />}
              dropdownRender={(menu) => <CustomSelectDropdown menu={menu} />}
              dropdownClassName="custom-dropdown-class"
            >
              <Option value="Consignee">Consignee</Option>
              <Option value="Transporter">Transporter</Option>
              <Option value="Clearing Agent">Clearing Agent</Option>
              <Option value="Other">Other</Option>
            </CustomSelect>
          </FormItem>

          <FormItem label="Company Name" name="company_name">
            <InputStyled placeholder="Company Name" />
          </FormItem>

          <FormItem
            label="Phone"
            name="phone"
            rules={[
              {
                message: "Please input your phone number !",
                required: true,
              },
            ]}
          >
            <InputStyled placeholder="Phone Number" />
          </FormItem>

          <FormItem
            label="Email"
            name="email"
            rules={[
              {
                type: "email",
                message: "The input is not a valid E-mail!",
              },
              {
                message: "Please input your Email!",
                required: true,
              },
            ]}
          >
            <InputStyled placeholder="Email Address" />
          </FormItem>

          <FormItem
            label="Address"
            name="address"
            rules={[
              {
                message: "Please input your address",
                required: true,
              },
            ]}
          >
            <InputStyled placeholder="Address" />
          </FormItem>

          <FormItem
            label="Password"
            name="password"
            rules={[{ message: "Please input your password", required: true }]}
          >
            <InputStyled.Password placeholder="Password" />
          </FormItem>

          <FormItem
            name="re_password"
            label="Confirm Password"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <InputStyled.Password />
          </FormItem>

          <OptionsWrapper>
            Already Have an Account? <Link to="/login">Login</Link>
          </OptionsWrapper>

          <FormItem className="btn-signin-wrapper">
            <ButtonStyled
              className="btn-signin"
              htmlType="submit"
              type="primary"
              size="large"
              full
              loading={loading}
            >
              Create Account
            </ButtonStyled>
          </FormItem>

          <InfoText>
            <span>
              By continuing, you agree to the Terms of Service and acknowledge
              our Privacy Policy
            </span>
          </InfoText>
        </Form>
      </AccountWrapper>

      <BgWrapper />
    </Section>
  );
};

export default CreateAccount;

const Section = styled.section`
  display: flex;
`;

const BgWrapper = styled.div`
  min-height: 100vh;
  width: 50%;
  background: url("/assets/img/SignupBg.png");
  background-size: cover;
  background-position: center;
`;

const AccountWrapper = styled.div`
  padding: 4rem 0;
  width: 50%;

  .title {
    text-align: center;
    margin-bottom: 2rem;
  }

  form {
    max-width: 500px;
    margin: auto;
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;

const OptionsWrapper = styled.div`
  text-align: center;
  color: #1c4e63;
  margin-bottom: 1rem;
  margin-top: 2rem;
`;

const InfoText = styled.div`
  max-width: 270px;
  margin: -12px auto 0;
  text-align: center;
  font-size: ${({ theme }) => theme["font-size-sm"]};
`;
