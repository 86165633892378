import React, { useState } from "react";
import { Section } from "pages/dashboard/styles";
import Heading from "components/common/heading";
import api from "api";
import { Form, Modal } from "antd";
import ButtonStyled from "components/common/buttonStyled";
import InputStyled from "components/common/inputStyled";
import { useNavigate } from "react-router-dom";

const FormItem = Form.Item;

const VerifyAccount = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();

      setIsLoading(true);

      const response = await api.auth.verifyAccount(values.otp);

      if (response.status === 200) {
        Modal.success({
          title: response.data.message,
          content: "Click on the button below to Login.",
          okText: "Login",
          onOk: () => navigate("/login"),
        });
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <Section>
      <div className="max-width-wrapper">
        <Heading className="title">Verify Account</Heading>
        <p>Enter OTP Code you received in your email.</p>

        <Form
          name="verifyAccount"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <FormItem
            label="OTP Code"
            name="otp"
            rules={[
              {
                message: "Enter OTP Code!",
                required: true,
              },
            ]}
          >
            <InputStyled placeholder="Enter OTP Code" />
          </FormItem>

          <FormItem className="btn-signin-wrapper">
            <ButtonStyled
              className="btn-signin"
              htmlType="submit"
              type="primary"
              size="large"
              full
              loading={isLoading}
            >
              Verify
            </ButtonStyled>
          </FormItem>
        </Form>
      </div>
    </Section>
  );
};

export default VerifyAccount;
